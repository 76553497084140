import React, { useEffect } from 'react'
import { adsenseClientId, adsenseSlotId } from '../../config/site'

// const AdContainer = styled.div`
//   padding-top: 2rem;
//   display: flex;
//   justify-content: center;
//   width: 100%;
//   min-height: 150px;
//   margin-bottom: 3rem;

//   * {
//     max-width: calc(100vw - 2rem);
//     object-fit: cover;
//     object-position: left;
//   }
// `

const AddContainer = () => {
  useEffect(() => {
    try {
      const adsbygoogle = window.adsbygoogle || []
      adsbygoogle.push({})
    } catch (e) {
      console.error(e)
    }
  }, [])

  if (!adsenseClientId || !adsenseSlotId) return null
  
  return (
    <>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client={adsenseClientId}
        data-ad-slot={adsenseSlotId}
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </>
  )
}

export default AddContainer
