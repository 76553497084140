export const defaultSideBarLinks = [
  { title: 'British History', link: '/british-history-timeline' },
  { title: 'Winston Churchill', link: '/winston-churchill-biography' },
  { title: 'J.K. Rowling', link: '/jk-rowling-biography' },
  { title: 'William Shakespeare', link: '/william-shakespeare-biography' },
  { title: 'John Lennon', link: '/john-lennon-biography' },
]

export const suggestionLink = {
  title: '2-minute quiz',
  link: '/quiz',
}
