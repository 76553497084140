export const finalScore = {
  eight: 'Congratulations, you know your GreatestBritons!',
  six: 'Good work. But check out our British History page to get top marks!',
  four:
  "Not bad, but there's room for improvement!",
  other: 'You need to do some serious research!',
}

export const questions = [
  //BIRTH//
  {
    question: 'When was the Battle of Hastings?',
    choice1: 928,
    choice2: 1066,
    choice3: 1212,
    choice4: 1666,
    answer: 2,
  },
  {
    question: 'When did Tim Berners-Lee invent the internet?',
    choice1: 1986,
    choice2: 1987,
    choice3: 1989,
    choice4: 1991,
    answer: 4,
  },
  {
    question: 'When did the UK vote to leave Europe?',
    choice1: 'April 2015',
    choice2: 'June 2016',
    choice3: 'May 2017',
    choice4: 'July 2018',
    answer: 2,
  },
  {
    question: 'When was Queen Elizabeth II crowned?',
    choice1: 1950,
    choice2: 1953,
    choice3: 1957,
    choice4: 1960,
    answer: 2,
  },
  {
    question: 'When was the NHS founded?',
    choice1: 1948,
    choice2: 1950,
    choice3: 1953,
    choice4: 1955,
    answer: 1,
  },
  {
    question: 'When did D-Day take place?',
    choice1: 'April 1943',
    choice2: 'June 1943',
    choice3: 'April 1944',
    choice4: 'June 1944',
    answer: 4,
  },
  {
    question:
      'Who was Winston Churchill talking about when he said "Never in the field of human conflict was so much owed by so many to so few"?',
    choice1: 'Sub-mariners',
    choice2: 'Code breakers',
    choice3: 'Tank commanders',
    choice4: 'Pilots',
    answer: 4,
  },
  {
    question:
      'When did Edward VIII abdicate so that he could marry Wallace Simpson?',
    choice1: 1927,
    choice2: 1931,
    choice3: 1936,
    choice4: 1939,
    answer: 3,
  },
  {
    question: 'Whose assassination sparked the First World War?',
    choice1: 'Foreign Minister Sergey Sazonov',
    choice2: 'Archduke Franz Ferdinand',
    choice3: 'Kaiser Wilhelm',
    choice4: 'Tsar Nicholas II',
    answer: 2,
  },
  {
    question: 'When did Queen Victoria die?',
    choice1: 1899,
    choice2: 1900,
    choice3: 1901,
    choice4: 1904,
    answer: 3,
  },
  {
    question: 'When did Darwin publish On the Origin of Species?',
    choice1: 1845,
    choice2: 1855,
    choice3: 1859,
    choice4: 1865,
    answer: 3,
  },
  {
    question: 'When was the Battle of Waterloo?',
    choice1: 1805,
    choice2: 1810,
    choice3: 1815,
    choice4: 1820,
    answer: 3,
  },
   {
    question: 'When was the Battle of Trafalgar?',
    choice1: 1805,
    choice2: 1810,
    choice3: 1815,
    choice4: 1820,
    answer: 1,
  },
     {
    question: 'When was the Great Fire of London?',
    choice1: 1466,
    choice2: 1566,
    choice3: 1666,
    choice4: 1766,
    answer: 3,
  },
       {
    question: 'When was Charles I executed?',
    choice1: 1559,
    choice2: 1609,
    choice3: 1649,
    choice4: 1699,
    answer: 3,
  },
         {
    question: 'When was the Spanish Armada?',
    choice1: 1588,
    choice2: 1609,
    choice3: 1649,
    choice4: 1699,
    answer: 1,
  },
           {
    question: 'When was the Battle of Bosworth Field?',
    choice1: 1389,
    choice2: 1450,
    choice3: 1470,
    choice4: 1485,
    answer: 4,
  },
             {
    question: 'Which monarch was defeated at the Battle of Bosworth Field?',
    choice1: 'Richard II',
    choice2: 'Richard III',
    choice3: 'Henry VII',
    choice4: 'Henry VIII',
    answer: 3,
  },
             {
    question: 'When was Magna Carta signed?',
    choice1: 1215,
    choice2: 1300,
    choice3: 1447,
    choice4: 1586,
    answer: 1,
  },
               {
    question: 'When did the Romans invade England?',
    choice1: '43 BC',
    choice2: '13 BC',
    choice3: '43 AD',
    choice4: '410 AD',
    answer: 3,
  },
]